import ComponentRepository from './architecture/componentRepository';

document.addEventListener('DOMContentLoaded', function () {
    window.cr = new ComponentRepository({
        'header': () => import('./components/top-navigation'),
        'hero-swiper': () => import('./components/hero-swiper'),
        'short-content-swiper': () => import('./components/short-content-swiper'),
        'content-grid-swiper': () => import('./components/content-grid-swiper'),
        'knowledge-swiper': () => import('./components/knowledge-swiper'),
        'image-gallery': () => import('./components/image-gallery'),
        'accordion': () => import('./components/accordion'),
        'top-navigation': () => import('./components/top-navigation'),
        'mobile-navigation': () => import('./components/mobile-navigation'),
        'media-with-video': () => import('./components/media-with-video'),
        'swiper': () => import('../../node_modules/swiper/swiper-bundle.js'),
        'modal': () => import('./components/modal'),
        'map': () => import('./components/map'),
        'grid-item': () => import('./components/grid-item'),
        'knowledge-search': () => import('./components/knowledge-search'),
        'site-search': () => import('./components/site-search'),
        'latest-knowledge': () => import('./components/latest-knowledge'),
        'animated-line': () => import('./components/animated-line'),
        'knowledge-article': () => import('./components/knowledge-article'),
        'maillist-signup': () => import('./components/maillist-signup'),
        'checkbox': () => import('./components/checkbox'),
        /*
        This is an example component that demonstrates how to implement a filterable map. It should not be used in production sites as is but can serve as inspiration.
        'domain-filterable-map': () => import('./components/example-components/example-domain-filterable-map'),
        */
    });
});
